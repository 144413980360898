var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"breakout-comp"},[_vm._l((_vm.speakerData.tracks),function(track){return _c('div',{key:track.id,style:({
      backgroundImage: ("url(" + (require('@/assets/img/speaker/desktop/' +
        track.bg)) + ")"),
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    })},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 my-4"},[_c('h2',{staticClass:"heading"},[_c('span',[_vm._v(_vm._s(track.title))])])]),_vm._l((track.list),function(speakerItem){return _c('div',{key:speakerItem.id,staticClass:"col-6 col-sm-3 mb-3 speaker-item",on:{"click":function($event){return _vm.openModal(speakerItem)}}},[_c('img',{staticClass:"img-fluid w-100",attrs:{"src":require(("../../assets/img/speaker/desktop/breakout/" + (speakerItem.thumbnail))),"alt":"avatar"}}),_c('div',{staticClass:"mt-2 text-white"},[_c('p',{staticClass:"mb-0 b-600 text-uppercase"},[_vm._v(_vm._s(speakerItem.name))]),_c('p',{staticClass:"small"},[_vm._v(_vm._s(speakerItem.designation))])])])})],2)])])}),(_vm.selected_speaker)?_c('div',{staticClass:"modal fade speakerprofile",attrs:{"id":"speakerpopup","role":"dialog"}},[_c('div',{staticClass:"modal-dialog modal-xl modal-dialog-centered"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-body p-0"},[_c('button',{staticClass:"close text-white",attrs:{"type":"button","data-dismiss":"modal"}},[_c('svg',{staticClass:"bi bi-x-lg",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","fill":"#fff","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"}}),_c('path',{attrs:{"fill-rule":"evenodd","d":"M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"}})])]),(
              _vm.isUADesktop ||
              (_vm.isUAMobile && !_vm.isPotrait) ||
              (_vm.isUATablet && !_vm.isPotrait)
            )?_c('img',{staticClass:"img-fluid",attrs:{"src":require(("../../assets/img/speaker/desktop/breakout/" + (_vm.selected_speaker.bio))),"alt":"speaker bio"}}):_vm._e(),((_vm.isUAMobile && _vm.isPotrait) || (_vm.isUATablet && _vm.isPotrait))?_c('img',{staticClass:"img-fluid",attrs:{"src":require(("../../assets/img/speaker/mobile/breakout/" + (_vm.selected_speaker.mbbio))),"alt":"speaker bio"}}):_vm._e()])])])]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }