<template>
  <div class="speakers">
    <div class="container-lg">
      <section class="content bg-primary">
        <div class="close-back">
          <router-link to="/lobby">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#fff"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-x"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </router-link>
        </div>
        <div class="container-fluid p-0">
          <div class="row align-items-center">
            <div class="col-sm-12">
              <img
                src="@/assets/img/speaker/desktop/breakout/topbanner.png"
                class="img-fluid"
                alt="text"
              />
            </div>
          </div>
        </div>
        <conference
          :speakerData="current_category"
          v-if="current_category.slug == 'conference_speakers'"
        />
        <breakout
          :speakerData="current_category"
          v-if="current_category.slug == 'breakout_room_speakers'"
        />
      </section>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import conference from "../components/speaker/conference.vue";
import breakout from "../components/speaker/breakout.vue";

export default {
  components: { conference },
  name: "speakers",
  computed: {
    ...mapState(["speakers"]),
  },
  components: {
    conference,
    breakout,
  },
  data() {
    return {
      current_category: "",
    };
  },
  created() {
    this.current_category = this.speakers[this.$route.params.slug];
  },
};
</script>

<style lang="scss" scoped>
.speakers {
  min-height: 100vh;
  background: url(../assets/img/speaker/desktop/opacitybg.png) no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;

  & .content {
    min-height: 100vh;
    position: relative;
  }
}
</style>
