<template>
  <div class="conference-comp">
    <div v-for="track in speakerData.tracks" :key="track.id">
      <div class="row no-gutters heading-strip mb-4">
        <div class="col-sm-12 bg-info px-3 py-2">
          <h1>meet our speakers</h1>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12 mb-4">
            <h2 class="sub-heading">
              <span class="shadow">{{ track.title }}</span>
            </h2>
          </div>
          <div
            class="col-6 col-sm-3 mb-3 speaker-item"
            v-for="speakerItem in track.list"
            :key="speakerItem.id"
            @click="openModal(speakerItem)"
          >
            <img
              :src="
                require(`../../assets/img/speaker/desktop/conference/${speakerItem.thumbnail}`)
              "
              class="img-fluid w-100"
              alt="avatar"
            />
            <div class="mt-2 text-white">
              <p class="mb-0 b-600 text-uppercase">{{ speakerItem.name }}</p>
              <p class="small mb-1">{{ speakerItem.designation }}</p>
              <p class="small mb-1" v-if="speakerItem.designation2">
                {{ speakerItem.designation2 }}
              </p>
              <p class="small" v-if="speakerItem.designation3">
                {{ speakerItem.designation3 }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="selected_speaker"
      class="modal fade speakerprofile"
      id="speakerpopup"
      role="dialog"
    >
      <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body p-0">
            <button type="button" class="close text-white" data-dismiss="modal">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="#1a1a1a"
                class="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
                />
                <path
                  fill-rule="evenodd"
                  d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
                />
              </svg>
            </button>
            <img
              :src="
                require(`../../assets/img/speaker/desktop/conference/${selected_speaker.bio}`)
              "
              class="img-fluid"
              alt="speaker bio"
              v-if="
                isUADesktop ||
                (isUAMobile && !isPotrait) ||
                (isUATablet && !isPotrait)
              "
            />
            <img
              :src="
                require(`../../assets/img/speaker/mobile/conference/${selected_speaker.mbbio}`)
              "
              class="img-fluid"
              alt="speaker bio"
              v-if="(isUAMobile && isPotrait) || (isUATablet && isPotrait)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import UAParser from "ua-parser-js";

export default {
  name: "conferencecomponent",
  props: ["speakerData"],
  data() {
    return {
      selected_speaker: null,
      isUADesktop: false,
      isUAMobile: false,
      isUATablet: false,
      isPotrait: false,
    };
  },
  methods: {
    openModal(speaker) {
      try {
        this.selected_speaker = speaker;
      } finally {
        $("#speakerpopup").modal("show");
      }
    },
    getOrientation() {
      var mql = window.matchMedia("(orientation: portrait)");

      if (mql.matches) {
        this.isPotrait = true;
      } else {
        this.isPotrait = false;
      }

      mql.addListener(function (m) {
        if (m.matches) {
          this.isPotrait = true;
        } else {
          this.isPotrait = false;
        }
      });
    },
  },
  mounted() {
    $(window).bind("orientationchange", function (event) {
      location.reload(true);
    });
    let parser = new UAParser();

    let parser_result = parser.getResult();

    if (parser_result.device.type == "mobile") {
      this.isUAMobile = true;
    } else if (parser_result.device.type == "tablet") {
      this.isUATablet = true;
    } else {
      this.isUADesktop = true;
    }

    this.getOrientation();
  },
};
</script>

<style lang="scss" scoped>
.conference-comp {
  & .heading-strip {
    & h1 {
      color: #fff;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 1.5rem;
      margin-bottom: 0;
    }
  }
  & .sub-heading {
    & span {
      color: #1a1a1a;
      background: $light-blue;
      text-transform: uppercase;
      border-radius: 40px;
      padding: 10px 15px;
      font-size: 1.2rem;
      font-weight: 600;
    }
  }
  & .speaker-item {
    cursor: pointer;
  }
}
</style>
