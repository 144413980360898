<template>
  <div class="breakout-comp">
    <div
      v-for="track in speakerData.tracks"
      :key="track.id"
      :style="{
        backgroundImage: `url(${require('@/assets/img/speaker/desktop/' +
          track.bg)})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }"
    >
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12 my-4">
            <h2 class="heading">
              <span>{{ track.title }}</span>
            </h2>
          </div>
          <div
            class="col-6 col-sm-3 mb-3 speaker-item"
            v-for="speakerItem in track.list"
            :key="speakerItem.id"
            @click="openModal(speakerItem)"
          >
            <img
              :src="
                require(`../../assets/img/speaker/desktop/breakout/${speakerItem.thumbnail}`)
              "
              class="img-fluid w-100"
              alt="avatar"
            />
            <div class="mt-2 text-white">
              <p class="mb-0 b-600 text-uppercase">{{ speakerItem.name }}</p>
              <p class="small">{{ speakerItem.designation }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="selected_speaker"
      class="modal fade speakerprofile"
      id="speakerpopup"
      role="dialog"
    >
      <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body p-0">
            <button type="button" class="close text-white" data-dismiss="modal">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="#fff"
                class="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
                />
                <path
                  fill-rule="evenodd"
                  d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
                />
              </svg>
            </button>
            <img
              :src="
                require(`../../assets/img/speaker/desktop/breakout/${selected_speaker.bio}`)
              "
              class="img-fluid"
              alt="speaker bio"
              v-if="
                isUADesktop ||
                (isUAMobile && !isPotrait) ||
                (isUATablet && !isPotrait)
              "
            />
            <img
              :src="
                require(`../../assets/img/speaker/mobile/breakout/${selected_speaker.mbbio}`)
              "
              class="img-fluid"
              alt="speaker bio"
              v-if="(isUAMobile && isPotrait) || (isUATablet && isPotrait)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import UAParser from "ua-parser-js";
export default {
  name: "breakoutcomponent",
  props: ["speakerData"],
  data() {
    return {
      selected_speaker: null,
      isUADesktop: false,
      isUAMobile: false,
      isUATablet: false,
      isPotrait: false,
    };
  },
  methods: {
    openModal(speaker) {
      try {
        this.selected_speaker = speaker;
      } finally {
        $("#speakerpopup").modal("show");
      }
    },
    getOrientation() {
      var mql = window.matchMedia("(orientation: portrait)");

      if (mql.matches) {
        this.isPotrait = true;
      } else {
        this.isPotrait = false;
      }

      mql.addListener(function (m) {
        if (m.matches) {
          this.isPotrait = true;
        } else {
          this.isPotrait = false;
        }
      });
    },
  },
  mounted() {
    $(window).bind("orientationchange", function (event) {
      location.reload(true);
    });
    let parser = new UAParser();

    let parser_result = parser.getResult();

    if (parser_result.device.type == "mobile") {
      this.isUAMobile = true;
    } else if (parser_result.device.type == "tablet") {
      this.isUATablet = true;
    } else {
      this.isUADesktop = true;
    }

    this.getOrientation();
  },
};
</script>

<style lang="scss" scoped>
.breakout-comp {
  & .heading {
    & span {
      color: #fff;
      text-transform: uppercase;
      font-size: 1.5rem;
      font-weight: 700;
      margin-bottom: 0;
    }
  }

  & .speaker-item {
    cursor: pointer;
  }
}
</style>
